const primaryColors = {
	white: 'hsl(0deg, 0%, 100%)',
	darkerWhite: 'hsl(0, 0%, 75%)',
	lighterWhite: 'hsl(0, 0%, 95%)',
	grey: 'hsl(0deg, 0%, 50%)',
	black: 'hsl(0deg, 0%, 0%)',
}

const sectionColors = {
	articles: '#FFC107',
	library: '#F27405',
	webinars: '#D92B04',
	products: '#A10B79',
	companies: '#2975D9',
	career: '#2FC3D4',
}

export type SectionColor = keyof typeof sectionColors

const colors = {
	...sectionColors,
	academy: sectionColors.articles,
	events: sectionColors.articles,

	webinar: sectionColors.webinars,
	news: sectionColors.articles,
	black: primaryColors.black,
	shadow: 'RGBA(0, 0, 0, 0.16)',
	shadowStrong: 'RGBA(0, 0, 0, 0.3)',
	background: '#ECECEC',
	backgroundDarker: '#BEBEBE',
	white: primaryColors.white,
	darkerWhite: primaryColors.darkerWhite,
	lighterWhite: primaryColors.lighterWhite,
	lighterBlack: primaryColors.grey,
	text: primaryColors.black,
	commonButtonBorder: primaryColors.black,
	boxBackground: primaryColors.white,
	imageTitleText: primaryColors.grey,
	importantNotice: '#BA2525',

	header: {
		background: primaryColors.black,
	},
}

export default colors
